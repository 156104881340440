import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { FilledButton } from '../components/common'
import { ContentContainer, Row } from '../components/utils'

const CenterRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Page Not Found" />
    <ContentContainer>
      <CenterRow pt="sm">
        <h1>Page not found</h1>
      </CenterRow>
      <CenterRow pb="sm" pt="sm">
        <FilledButton to="/">Return to the home page</FilledButton>
      </CenterRow>
    </ContentContainer>
  </Layout>
)

export default NotFoundPage
